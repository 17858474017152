export default [
  {
    route: 'e-commerce-dashboard',
    title: 'e-commerce-dashboard',
    slug: 'dashboard',
    icon: 'HomeIcon',
    i18n: 'Dashboard',
    resource: 'E-commerce-dashboard',
    action: 'read',
  },

  {
    route: null,
    title: 'e-commerce-users',
    slug: 'users',
    icon: 'UsersIcon',
    resource: 'E-commerce-users',
    i18n: 'Users',
    children: [
      {
        route: 'e-commerce-users-admin',
        title: 'e-commerce-users-admin',
        slug: 'userAdmin',
        icon: 'BriefcaseIcon',
        i18n: 'Admin',
        resource: 'E-commerce-users-admin',
        action: 'read',
      },
      {
        route: 'e-commerce-users-staff',
        title: 'e-commerce-users-staff',
        slug: 'userStaff',
        icon: 'PackageIcon',
        i18n: 'Staff',
        resource: 'E-commerce-users-staff',
        action: 'read',
      },
      {
        route: 'e-commerce-permission-in-app',
        title: 'e-commerce-permission-in-app',
        slug: 'permissionInApp',
        icon: 'PackageIcon',
        i18n: 'Permission in app',
        resource: 'E-commerce-permission-in-app',
        action: 'read',
      },
    ],
  },
  {
    route: 'e-commerce-chat',
    title: 'e-commerce-chat',
    slug: 'chat',
    icon: 'MessageSquareIcon',
    i18n: 'Chat',
    resource: 'E-commerce-chat',
    action: 'read',
  },
  {
    route: null,
    title: 'e-commerce-warehouse',
    slug: 'warehouse',
    icon: 'LayersIcon',
    resource: 'E-commerce-warehouse',
    i18n: 'Warehouse',
    children: [
      {
        route: 'e-commerce-main-warehouse',
        title: 'e-commerce-main-warehouse',
        slug: 'mainWarehouse',
        icon: 'LayersIcon',
        i18n: 'Main Warehouse',
        resource: 'E-commerce-main-warehouse',
        action: 'read',
      },
      {
        route: 'e-commerce-product-warehouse',
        title: 'e-commerce-product-warehouse',
        slug: 'productWarehouse',
        icon: 'LayersIcon',
        i18n: 'Product',
        resource: 'E-commerce-product-warehouse',
        action: 'read',
      },
      {
        route: 'e-commerce-warehouse',
        title: 'e-commerce-warehouse',
        slug: 'warehouse',
        icon: 'LayersIcon',
        i18n: 'Warehouse',
        resource: 'E-commerce-warehouse',
        action: 'read',
      },
      {
        route: 'e-commerce-branch-warehouse',
        title: 'e-commerce-branch-warehouse',
        slug: 'branchWarehouse',
        icon: 'LayersIcon',
        i18n: 'Branch/Store',
        resource: 'E-commerce-branch-warehouse',
        action: 'read',
      },
      {
        route: 'e-commerce-online-store-warehouse',
        title: 'e-commerce-online-store-warehouse',
        slug: 'onlineStoreWarehouse',
        icon: 'LayersIcon',
        i18n: 'Online Store',
        resource: 'E-commerce-online-store-warehouse',
        action: 'read',
      },
      {
        route: 'e-commerce-product-transfer-list-warehouse',
        title: 'e-commerce-product-transfer-list-warehouse',
        slug: 'onlineStoreWarehouse',
        icon: 'LayersIcon',
        i18n: 'Product transfer list',
        resource: 'E-commerce-product-transfer-list-warehouse',
        action: 'read',
      },
    ],
  },
  {
    route: null,
    title: 'e-commerce-reservation',
    slug: 'reservation',
    icon: 'CalendarIcon',
    resource: 'E-commerce-reservation',
    i18n: 'Reservation System',
    children: [
      {
        route: 'e-commerce-reservation-booking',
        title: 'e-commerce-reservation-booking',
        slug: 'reservationBooking',
        icon: 'BoxIcon',
        i18n: 'Booking',
        resource: 'E-commerce-reservation-booking',
        action: 'read',
      },
      {
        route: 'e-commerce-reservation-pick-course-package',
        title: 'e-commerce-reservation-pick-course-package',
        slug: 'reservationPickCoursePackage',
        icon: 'BoxIcon',
        i18n: 'Pick Course / Package',
        resource: 'E-commerce-reservation-pick-course-package',
        action: 'read',
      },
      {
        route: 'e-commerce-reservation-category-course-package',
        title: 'e-commerce-reservation-category-course-package',
        slug: 'reservationCategoryCoursePackage',
        icon: 'BoxIcon',
        i18n: 'Category',
        resource: 'E-commerce-reservation-category-course-package',
        action: 'read',
      },
      // {
      //   route: 'e-commerce-reservation-course-package',
      //   title: 'e-commerce-reservation-course-package',
      //   slug: 'reservationCoursePackage',
      //   icon: 'BoxIcon',
      //   i18n: 'Course / Package',
      //   resource: 'E-commerce-reservation-course-package',
      //   action: 'read',
      // },
      {
        route: 'e-commerce-reservation-course',
        title: 'e-commerce-reservation-course',
        slug: 'reservationCourse',
        icon: 'BoxIcon',
        i18n: 'Course',
        resource: 'E-commerce-reservation-course',
        action: 'read',
      },
      {
        route: 'e-commerce-reservation-package',
        title: 'e-commerce-reservation-package',
        slug: 'reservationPackage',
        icon: 'BoxIcon',
        i18n: 'Package',
        resource: 'E-commerce-reservation-package',
        action: 'read',
      },
      {
        route: 'e-commerce-reservation-room',
        title: 'e-commerce-reservation-room',
        slug: 'reservationRoom',
        icon: 'BoxIcon',
        i18n: 'Room',
        resource: 'E-commerce-reservation-room',
        action: 'read',
      },
      // {
      //   route: 'e-commerce-reservation-buying-package',
      //   title: 'e-commerce-reservation-buying-package',
      //   slug: 'reservationBuyingPackage',
      //   icon: 'BoxIcon',
      //   i18n: 'Buying a package',
      //   resource: 'E-commerce-reservation-buying-package',
      //   action: 'read',
      // },

    ],
  },
  {
    route: null,
    title: 'e-commerce-warranty',
    slug: 'warranty',
    icon: 'SettingsIcon',
    i18n: 'Warranty',
    resource: 'E-commerce-warranty',
    action: 'read',
    children: [
      {
        route: 'e-commerce-warranty-service',
        title: 'e-commerce-warranty-service',
        slug: 'warranty-service',
        icon: 'SettingsIcon',
        i18n: 'Warranty Service',
        resource: 'E-commerce-warranty-service',
        action: 'read',
      },
      {
        route: 'e-commerce-claim-service',
        title: 'e-commerce-claim-service',
        slug: 'claim-service',
        icon: 'SettingsIcon',
        i18n: 'Claim Service',
        resource: 'E-commerce-claim-service',
        action: 'read',
      },
    ],
  },
  {
    route: 'e-commerce-productCategory',
    title: 'e-commerce-productCategory',
    slug: 'productCategory',
    icon: 'BoxIcon',
    i18n: 'Product Category',
    resource: 'E-commerce-productCategory',
    action: 'read',
  },
  {
    route: 'e-commerce-productBrand',
    title: 'e-commerce-productBrand',
    slug: 'productBrand',
    icon: 'BoxIcon',
    i18n: 'Product Brand',
    resource: 'E-commerce-productBrand',
    action: 'read',
  },
  {
    route: 'e-commerce-product',
    title: 'e-commerce-product',
    slug: 'product',
    icon: 'BoxIcon',
    i18n: 'Product',
    resource: 'E-commerce-product',
    action: 'read',
  },
  {
    route: 'e-commerce-feed',
    title: 'e-commerce-feed',
    slug: 'feed',
    icon: 'TvIcon',
    i18n: 'Feed',
    resource: 'E-commerce-feed',
    action: 'read',
  },
  {
    route: null,
    title: 'e-commerce-manage-audio',
    slug: 'manageAudio',
    icon: 'ArchiveIcon',
    resource: 'E-commerce-manage-audio',
    i18n: 'Manage audio',
    children: [
      {
        route: 'e-commerce-audio-category',
        title: 'e-commerce-audio-category',
        slug: 'audioCategory',
        icon: 'BriefcaseIcon',
        i18n: 'Audio category',
        resource: 'E-commerce-audio-category',
        action: 'read',
      },
      {
        route: 'e-commerce-audio',
        title: 'e-commerce-audio',
        slug: 'audio',
        icon: 'PackageIcon',
        i18n: 'Audio',
        resource: 'E-commerce-audio',
        action: 'read',
      },
    ],
  },
  {
    route: 'e-commerce-promotion',
    title: 'e-commerce-promotion',
    slug: 'promotion',
    icon: 'FilmIcon',
    i18n: 'Promotion',
    resource: 'E-commerce-promotion',
    action: 'read',
  },
  {
    route: 'e-commerce-coupon',
    title: 'e-commerce-coupon',
    slug: 'coupon',
    icon: 'FilmIcon',
    i18n: 'Coupon',
    resource: 'E-commerce-coupon',
    action: 'read',
  },
  {
    route: 'e-commerce-flash-sale',
    title: 'e-commerce-flash-sale',
    slug: 'flashSale',
    icon: 'CloudLightningIcon',
    i18n: 'Flash Sale',
    resource: 'E-commerce-flash-sale',
    action: 'read',
  },
  {
    route: 'e-commerce-promote',
    title: 'e-commerce-promote',
    slug: 'promote',
    icon: 'StarIcon',
    i18n: 'Home app menu',
    resource: 'E-commerce-promote',
    action: 'read',
  },
  {
    route: null,
    title: 'e-commerce-shiping',
    slug: 'shiping',
    icon: 'TruckIcon',
    i18n: 'Shiping',
    resource: 'E-commerce-shiping',
    action: 'read',
    children: [
      {
        route: 'e-commerce-shiping-wholesale-box',
        title: 'e-commerce-shiping-wholesale-box',
        slug: 'order',
        icon: 'ListIcon',
        i18n: 'Wholesale box',
        resource: 'E-commerce-shiping-wholesale-box',
        action: 'read',
      },
      {
        route: 'e-commerce-shiping-customer',
        title: 'e-commerce-shiping-customer',
        slug: 'order',
        icon: 'ListIcon',
        i18n: 'Customers choose',
        resource: 'E-commerce-shiping-customer',
        action: 'read',
      },
      {
        route: 'e-commerce-shiping-by-order',
        title: 'e-commerce-shiping-by-order',
        slug: 'order',
        icon: 'ListIcon',
        i18n: 'Order',
        resource: 'E-commerce-shiping-by-order',
        action: 'read',
      },
      {
        route: 'e-commerce-shiping-by-weight',
        title: 'e-commerce-shiping-by-weight',
        slug: 'order',
        icon: 'ListIcon',
        i18n: 'Weight',
        resource: 'E-commerce-shiping-by-weight',
        action: 'read',
      },
    ],
  },
  {
    route: 'e-commerce-push-notification',
    title: 'e-commerce-push-notification',
    slug: 'pushNotification',
    icon: 'PhoneIncomingIcon',
    i18n: 'Push Notification',
    resource: 'E-commerce-push-notification',
    action: 'read',
  },
  {
    route: 'e-commerce-notification',
    title: 'e-commerce-notification',
    slug: 'notification',
    icon: 'BellIcon',
    i18n: 'Notification',
    resource: 'E-commerce-notification',
    action: 'read',
  },
  {
    route: 'e-commerce-calendar-content',
    title: 'e-commerce-calendar-content',
    slug: 'calendarContent',
    icon: 'CalendarIcon',
    i18n: 'Calendar Content',
    resource: 'E-commerce-calendar-content',
    action: 'read',
  },
  {
    route: null,
    title: 'e-commerce-order',
    slug: 'order',
    icon: 'ListIcon',
    i18n: 'Order',
    resource: 'E-commerce-order',
    action: 'read',
    children: [
      {
        route: 'e-commerce-order',
        title: 'e-commerce-order',
        slug: 'order',
        icon: 'ListIcon',
        i18n: 'Order',
        resource: 'E-commerce-order',
        action: 'read',
      },
      {
        route: 'e-commerce-confirm-payment',
        title: 'e-commerce-confirm-payment',
        slug: 'confirmPayment',
        icon: 'ListIcon',
        i18n: 'ConfirmPayment',
        resource: 'E-commerce-confirm-payment',
        action: 'read',
      },
    ],
  },

  {
    route: null,
    title: 'e-commerce-member',
    slug: 'member',
    icon: 'UsersIcon',
    resource: 'E-commerce-member',
    i18n: 'Member',
    children: [
      {
        route: 'e-commerce-member-group',
        title: 'e-commerce-member-group',
        slug: 'memberGroup',
        icon: 'UsersIcon',
        i18n: 'Member Group',
        resource: 'E-commerce-member-group',
        action: 'read',
      },
      {
        route: 'e-commerce-member-level',
        title: 'e-commerce-member-level',
        slug: 'memberLevel',
        icon: 'UsersIcon',
        i18n: 'Member Level',
        resource: 'E-commerce-member-level',
        action: 'read',
      },
      {
        route: 'e-commerce-member-list',
        title: 'e-commerce-member-list',
        slug: 'memberList',
        icon: 'UsersIcon',
        i18n: 'Member List',
        resource: 'E-commerce-member-list',
        action: 'read',
      },
      {
        route: 'e-commerce-member-docs',
        title: 'e-commerce-member-docs',
        slug: 'memberDocs',
        icon: 'UsersIcon',
        i18n: 'Member Docs',
        resource: 'E-commerce-member-docs',
        action: 'read',
      },
    ],
  },

  {
    route: null,
    title: 'e-commerce-employee',
    slug: 'employee',
    icon: 'UsersIcon',
    resource: 'E-commerce-employee',
    i18n: 'Employee',
    children: [
      {
        route: 'e-commerce-employee-list',
        title: 'e-commerce-employee-list',
        slug: 'employeeList',
        icon: 'UsersIcon',
        i18n: 'Employee List',
        resource: 'E-commerce-employee-list',
        action: 'read',
      },
    ],
  },
  {
    route: null,
    title: 'e-commerce-report',
    slug: 'report',
    icon: 'FileTextIcon',
    resource: 'E-commerce-report',
    i18n: 'Report',
    children: [
      {
        route: 'e-commerce-member-income',
        title: 'e-commerce-member-income',
        slug: 'memberIncome',
        icon: 'UserCheckIcon',
        i18n: 'Member Income',
        resource: 'E-commerce-member-income',
        action: 'read',
      },
      {
        route: 'e-commerce-member-invitation',
        title: 'e-commerce-member-invitation',
        slug: 'memberInvitation',
        icon: 'UserPlusIcon',
        i18n: 'Member Invitation',
        resource: 'E-commerce-member-invitation',
        action: 'read',
      },
    ],
  },
  {
    route: null,
    title: 'e-commerce-mlm',
    slug: 'mlm',
    icon: 'ListIcon',
    resource: 'E-commerce-mlm',
    i18n: 'MLM',
    children: [
      {
        route: 'e-commerce-mlm-settings',
        title: 'e-commerce-mlm-settings',
        slug: 'mlmSettings',
        icon: 'SettingsIcon',
        i18n: 'Settings',
        resource: 'E-commerce-mlm-settings',
        action: 'read',
      },
    ],
  },
  {
    route: null,
    title: 'e-commerce-affiliate',
    slug: 'affiliate',
    icon: 'UsersIcon',
    resource: 'E-commerce-affiliate',
    i18n: 'Affiliate',
    children: [
      {
        route: 'e-commerce-affiliate-product-group',
        title: 'e-commerce-affiliate-product-group',
        slug: 'affiliateProductGroup',
        icon: 'BriefcaseIcon',
        i18n: 'Product Group',
        resource: 'E-commerce-affiliate-product-group',
        action: 'read',
      },
    ],
  },

  {
    route: null,
    title: 'e-commerce-credit-system',
    slug: 'creditSystem',
    icon: 'DollarSignIcon',
    resource: 'E-commerce-credit-system',
    i18n: 'Credit System',
    children: [
      {
        route: 'e-commerce-credit',
        title: 'e-commerce-credit',
        slug: 'credit',
        icon: 'CreditCardIcon',
        i18n: 'Credit',
        resource: 'E-commerce-credit',
        action: 'read',
      },
      {
        route: 'e-commerce-credit-confirm-payment',
        title: 'e-commerce-credit-confirm-payment',
        slug: 'creditConfirmPayment',
        icon: 'ListIcon',
        i18n: 'ConfirmPayment',
        resource: 'E-commerce-credit-confirm-payment',
        action: 'read',
      },
    ],
  },
  {
    route: null,
    title: 'e-commerce-credit-balance',
    slug: 'creditBalance',
    icon: 'DollarSignIcon',
    resource: 'E-commerce-credit-balance',
    i18n: 'Credit Balance',
    children: [
      {
        route: 'e-commerce-credit-balance-member',
        title: 'e-commerce-credit-balance-member',
        slug: 'creditBalanceMember',
        icon: 'UsersIcon',
        i18n: 'Member',
        resource: 'E-commerce-credit-balance-member',
        action: 'read',
      },
    ],
  },
  {
    route: null,
    title: 'e-commerce-point-system',
    slug: 'pointSystem',
    icon: 'DollarSignIcon',
    resource: 'E-commerce-point-system',
    i18n: 'Point System',
    children: [
      {
        route: 'e-commerce-point',
        title: 'e-commerce-point',
        slug: 'point',
        icon: 'CreditCardIcon',
        i18n: 'Point',
        resource: 'E-commerce-point',
        action: 'read',
      },
      // {
      //   route: 'e-commerce-point-confirm-payment',
      //   title: 'e-commerce-point-confirm-payment',
      //   slug: 'pointConfirmPayment',
      //   icon: 'ListIcon',
      //   i18n: 'ConfirmPayment',
      //   resource: 'E-commerce-point-confirm-payment',
      //   action: 'read',
      // },
    ],
  },
  {
    route: null,
    title: 'e-commerce-point-balance',
    slug: 'pointBalance',
    icon: 'DollarSignIcon',
    resource: 'E-commerce-point-balance',
    i18n: 'Point Balance',
    children: [
      {
        route: 'e-commerce-point-balance-member',
        title: 'e-commerce-point-balance-member',
        slug: 'pointBalanceMember',
        icon: 'UsersIcon',
        i18n: 'Member',
        resource: 'E-commerce-point-balance-member',
        action: 'read',
      },
    ],
  },
  {
    route: 'e-commerce-questionnaire',
    title: 'e-commerce-questionnaire',
    slug: 'questionnaire',
    icon: 'FileIcon',
    i18n: 'Questionnaire',
    resource: 'E-commerce-questionnaire',
    action: 'read',
  },
  {
    route: 'e-commerce-menu',
    title: 'e-commerce-menu',
    slug: 'menu',
    icon: 'MenuIcon',
    i18n: 'Menu',
    resource: 'E-commerce-menu',
    action: 'read',
  },
  {
    route: 'e-commerce-slide',
    title: 'e-commerce-slide',
    slug: 'slide',
    icon: 'SidebarIcon',
    i18n: 'Slide',
    resource: 'E-commerce-slide',
    action: 'read',
  },
  {
    route: 'e-commerce-upload-file',
    title: 'e-commerce-upload-file',
    slug: 'uploadFile',
    icon: 'MenuIcon',
    i18n: 'Upload File',
    resource: 'E-commerce-upload-file',
    action: 'read',
  },
  {
    route: null,
    title: 'e-commerce-setting',
    slug: 'setting',
    icon: 'ListIcon',
    i18n: 'Setting',
    resource: 'E-commerce-setting',
    action: 'read',
    children: [
      {
        route: 'e-commerce-images-settings',
        title: 'e-commerce-images-settings',
        slug: 'imagesSettings',
        icon: 'SettingsIcon',
        i18n: 'Images Settings',
        resource: 'E-commerce-images-settings',
        action: 'read',
      },
      {
        route: 'e-commerce-application-settings',
        title: 'e-commerce-application-settings',
        slug: 'applicationSettings',
        icon: 'SettingsIcon',
        i18n: 'Application Settings',
        resource: 'E-commerce-application-settings',
        action: 'read',
      },
      {
        route: 'e-commerce-set-application-color',
        title: 'e-commerce-set-application-color',
        slug: 'setApplicationColor',
        icon: 'SettingsIcon',
        i18n: 'Set Application Color',
        resource: 'E-commerce-set-application-color',
        action: 'read',
      },
      {
        route: 'e-commerce-style-application',
        title: 'e-commerce-style-application',
        slug: 'styleApplication',
        icon: 'SettingsIcon',
        i18n: 'Style Application',
        resource: 'E-commerce-style-application',
        action: 'read',
      },
      {
        route: 'e-commerce-set-up-system',
        title: 'e-commerce-set-up-system',
        slug: 'setUpSystem',
        icon: 'GlobeIcon',
        i18n: 'Set Up System',
        resource: 'E-commerce-set-up-system',
        action: 'read',
      },
      {
        route: 'e-commerce-set-support',
        title: 'e-commerce-set-support',
        slug: 'setSupport',
        icon: 'SettingsIcon',
        i18n: 'Set Support',
        resource: 'E-commerce-set-support',
        action: 'read',
      },
      {
        route: 'e-commerce-set-up-payment-system',
        title: 'e-commerce-set-up-payment-system',
        slug: 'setUpPaymentSystem',
        icon: 'SettingsIcon',
        i18n: 'Set Up Payment System',
        resource: 'E-commerce-set-up-payment-system',
        action: 'read',
      },
      {
        route: 'e-commerce-set-up-currency',
        title: 'e-commerce-set-up-currency',
        slug: 'setUpCurrency',
        icon: 'SettingsIcon',
        i18n: 'Set Up Currency',
        resource: 'E-commerce-set-up-currency',
        action: 'read',
      },
      {
        route: 'e-commerce-terms-and-privacy',
        title: 'e-commerce-terms-and-privacy',
        slug: 'termsAndPrivacy',
        icon: 'SettingsIcon',
        i18n: 'Terms and privacy',
        resource: 'E-commerce-terms-and-privacy',
        action: 'read',
      },
    ],
  },
]
